<template>
  <span>
  <span>
    <v-btn @click="doLoad">Press to load.. {{alias}}</v-btn> {{loaded?'a':'b'}}
  </span>
  <span v-if="dfeatured">
    Region: {{region}}
    <!-- +3minutes to game delay -->
    <p class="text-center font-weight-bold mb-0 pt-1"><v-icon left>mdi-clock</v-icon>{{ getGameTime() }}</p>
    <v-row dense>
      <v-col cols="6">
        <v-simple-table class="mb-1">
          <template #default class="">
            <p class="text-center font-weight-bold">TeamA</p>
            <tbody>
              <tr v-for="(item, a) in teamA" :key="a" @click="doSelect(item.summonerName,getImage(championList[item.championId]))">
                <td class="d-flex pa-0">
                  <v-img
                    :src="getImage(championList[item.championId])"
                    :lazy-src="`https://ddragon.leagueoflegends.com/cdn/11.15.1/img/champion/${championList[item.championId]}.png`"
                    max-width="48"
                    max-height="48"
                  />
                  <p class="d-block text-truncate pl-1" style="width:110px">{{ item.summonerName }}</p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="6">
        <v-simple-table>
          <template #default class="">
            <p class="text-center font-weight-bold">TeamB</p>
            <tbody>
              <tr v-for="(item, b) in teamB" :key="b" @click="doSelect(item.summonerName,getImage(championList[item.championId]))">
                <td class="d-flex justify-end pa-0">
                  <p class="d-block text-truncate pl-1" style="width:110px">{{ item.summonerName }}</p>
                  <v-img
                    :src="`https://ddragon.leagueoflegends.com/cdn/11.15.1/img/champion/${championList[item.championId]}.png`"
                    :lazy-src="`https://ddragon.leagueoflegends.com/cdn/11.15.1/img/champion/${championList[item.championId]}.png`"
                    max-width="48"
                    max-height="48"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </span>
  </span>
</template>

<script>
  import {
    mapState,
  } from 'vuex'
  export default {
    name: 'LOLCard',
    props: {
      alias: String,
      region: String,
      featured: Object,
    },
    data () {
      return {
        cdnVersion: '11.15.1',
        dfeatured: null,
        loaded: false,
        teamA: [],
        teamB: [],
        now: (new Date()).getTime(),
      }
    },
    async mounted () {
      // this.getChampions()
      if (!this.loaded) { // auto load?
        await this.doLoad()
      }
      console.log('doremount')
      this.updateTime()
    },
    computed: {
      ...mapState('platform', ['mainHost', 'championList']),
    },
    watch: {
      alias () {
        this.doLoad()
      },
    },
    methods: {
      async doLoad () {
        if (!this.alias) return
        console.log('get alias stats..', this.alias)
        var ret = (await this.$http.get(this.mainHost + '/game_status?game=lol&region=' + this.region + '&alias=' + this.alias)).data
        console.log('ret', ret)
        this.dfeatured = ret
        console.log('setloaded', this)
        this.getTeams()
        var ar = []
        var imgar = []
        if (!this.dfeatured.participants) return // not ingame
        for (var p of this.dfeatured.participants) {
          imgar.push(this.getImage(this.championList[p.championId]))
          ar.push(p.summonerName.toLowerCase())
        }
        console.log('ar', ar, imgar)
        this.$emit('find-invites', ar, this.region, imgar)
        this.loaded = true
      },
      updateTime () {
        this.now = (new Date()).getTime()
        setTimeout(this.updateTime, 1000)
      },
      getImage (champName) {
        return 'https://ddragon.leagueoflegends.com/cdn/11.15.1/img/champion/' + champName + '.png'
      },
      doSelect (alias, image) {
        console.log('z')
        this.$emit('onselect', alias, this.region, image)
      },
      getGameTime () {
        var startTime = this.dfeatured.gameStartTime
        startTime = startTime - (60 * 3 * 1000) // add 3 minutes from delay
        var time = (this.now - startTime) / 1000
        var min = Math.floor(time > 60 ? time / 60 : 0) + ':'
        var displayTime = (time > 60 ? min : '') + Math.floor(time % 60)

        return displayTime
      },
      getTeams () {
        this.teamA = []
        this.teamB = []
        if (!this.dfeatured || !this.dfeatured.participants) return
        for (var p of this.dfeatured.participants) {
          if (p.teamId === 100) this.teamA.push(p)
          else if (p.teamId === 200) this.teamB.push(p)
        }
        // console.log('ta', this.teamA)
      },
    },
  }
</script>
