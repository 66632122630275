<template>
  <div>
    <v-chip
      v-for="bet, i in bets"
      :key="i"
      class="mr-1 my-1 py-5"
    >
      <v-avatar left @click="avatarClick(bet.player)">
        <v-img width="32" :src="bet.avatar" />
      </v-avatar>

      <div class="d-flex flex-column py-5">
        <span>"{{ bet.player }}"</span><span v-if="hasTtv(bet.player)"> TTV</span>
        <span>{{ bet.total / 1000000000000000000 }} GGT</span>
      </div>
    </v-chip>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'BetsChips',
    props: {
      bets: {
        type: Array,
      },
      gameId: {
        type: String,
      },
    },
    data () {
      return {}
    },
    computed: {
      ...mapState('platform', ['mainHost', 'ttvHardlist']),
      ...mapState('rpc', ['address']),
    },
    methods: {
      ...mapActions('rpc', ['removeBet']),
      hasTtv (playerName) {
        return Object.values(this.ttvHardlist).find(k => k.alias.toLowerCase() === playerName)
      },
      async avatarClick (playerName, region) {
        /* console.log('avt', addr)
        if (addr === this.address) {
          console.log('avt2', addr)
          // var info = await this.platformContract.methods.getMatch(this.gameId).call()
          // console.log('info', info)
          return this.removeBet(this.gameId)
        } */
        // this.$router.push('/profile/' + addr)
        for (var ttv of Object.keys(this.ttvHardlist)) {
          if (this.ttvHardlist[ttv].alias.toLowerCase() === playerName) {
            this.$router.push(`/stream/${ttv}?game=lol`)
          }
        }
      },
    },
  }
</script>
