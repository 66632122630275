<template>
  <v-container>
    <v-row>
      <v-toolbar dense style="z-index: 1">
        <v-btn icon to="/twitchstreams">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-divider vertical inset class="mr-4" />
        <v-toolbar-title>{{ params.steamId }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <div class="">
          <div :id="`twitch-` + params.steamId" class="pa-0" />
        </div>
      </v-col>
    </v-row>

    <v-row>
    <v-col md="6">
        <LolCard :region="gameRegion" :alias="gameAlias" @onselect="selectLol" @find-invites="findInvites" />
    </v-col>
      <v-col md="6">
        <v-row>
          <v-col>
            <v-card
              color="#2a2e34"
            >
              <v-card-text>
                <v-overlay :value="overlay" absolute>
                            <v-card flat style="background: transparent">
                              <v-card-text>
                                <div class="d-flex justify-center align-center">

                                  <v-progress-circular
                                    indeterminate
                                    size="64"
                                  />
                                </div>
                              </v-card-text>
                              <v-card-title>{{ status[change_status] }}</v-card-title>
                            </v-card>
                          </v-overlay>
                <v-form ref="newbet" v-model="valid">
                  <v-row cols="12">
                    <v-text-field
                      v-model="node"
                      label="Node"
                      outlined
                      :disabled="!connected"
                      :rules="[(v) => !!v || 'Node is required']"
                    />
                  </v-row>
                  <v-row cols="12">
                    <v-text-field
                      v-model="mode"
                      label="Mode"
                      outlined
                      :disabled="!connected"
                      :rules="[(v) => !!v || 'Mode is required']"
                    />
                  </v-row>
                  <v-row cols="12" class="pb-0">
                    <v-text-field
                      v-model="maxplrs"
                      label="Max Players"
                      :disabled="!connected"
                      :rules="[(v) => !!v || 'Max Players is required']"
                      outlined
                    />
                  </v-row>
                  <v-row cols="12" class="pb-0">
                  <v-avatar left>
                    <v-img width="32" :src="joinImage" />
                  </v-avatar>
                  <v-text-field v-model="joinAlias" label="Alias" /> <v-text-field v-model="joinValue" label="Value" />
                  </v-row>
                  <v-row class="pt-0 d-flex justify-end">
                    <v-btn :disabled="!valid" class="success" @click="doCreateMatch">Create Match</v-btn>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="invite of tinvites" :key="invite.id" md="6">
        <InviteCard :showImg="true" ref="invCard" :invite="invite" :bets="betList(invite.gameId)" />
      </v-col>
      <v-col v-if="!invites">
        0 Invites
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import LoadScript from 'vue-plugin-load-script'
  import {
    mapActions,
    mapGetters,
    mapState,
  } from 'vuex'
  import LolCard from '../../components/featured/cards/lolCard.vue'
  import InviteCard from '../../components/invites/InviteCard.vue'

  Vue.use(LoadScript)
  export default {
    name: 'Stream',
    components: {
      LolCard,
      InviteCard,
    },
    data () {
      return {
        overlay: false,
        status: ['Approving Token...', 'Creating Match...', 'Joining Game...'],
        change_status: 0,
        // create cfg
        node: '',
        mode: 0,
        maxplrs: 8,
        valid: false,
        // etc
        joinImage: '',
        joinAlias: '',
        joinValue: 100,
        x: {},
        dialog: false,
        selectId: 0,
        selected: null,
        gameStatus: {},
        gameAlias: '',
        gameRegion: '',
        tinvites: [],
      }
    },
    computed: {
      ...mapGetters('rpc', ['invites']),
      ...mapState('route', ['params', 'query']),
      ...mapState('platform', ['mainHost']),
      ...mapState('rpc', ['s_bets', 'connected']),
      ...mapState('wallet', ['tokenAddress']),
      ...mapState('node', ['nodes', 'curRegion', 'featured', 'streams']),
    },
    watch: {
      node () {
        // this.getGames()
        console.log('nchange')
      },
      s_bets () {
        // this.getGames()
        console.log('nchange')
      },
    },
    async mounted () {
      Vue.loadScript('https://player.twitch.tv/js/embed/v1.js')
        .then(() => {
          const ttv = this.params.steamId
          var el = document.getElementById('twitch-' + ttv)

          const options = {
            width: '100%',
            height: '600',
            channel: ttv,
            layout: 'chat, video',
            autoplay: true,
            parent: ['localhost', 'goodgame.video'],
          }

          if (el && window.Twitch) {
            console.log(ttv + '-el', el)
            el.innerHTML = ''
            var t = new window.Twitch.Embed('twitch-' + ttv, options)
            t._iframe.setAttribute('class', 'twitch')
            console.log('t', t)
          }
        }).catch(e => (console.log(e)))

      this.gameStatus = (await this.$http.get(this.mainHost + '/game_status?game=' + this.query.game + '&twitch=' + this.params.steamId)).data
      console.log('ret', this.gameStatus, this.params)
      this.gameRegion = this.gameStatus.lol.region
      this.gameAlias = this.gameStatus.lol.alias
      this.joinAlias = this.gameAlias
      if (this.gameRegion && this.gameAlias) {
        this.findInvites([this.gameAlias.toLowerCase()], this.gameRegion)
      }
    },
    methods: {
      betList (gameId) {
        var ret = []
        for (var bet of this.s_bets) {
          if (bet.gameId === gameId) {
            ret.push(bet)
          }
        }
        return ret
      },
      async doCreateMatch () {
        // allow money first..
        var value = this.joinValue
        this.overlay = true

        if (!await this.aproveBet(value * 10)) {
          return console.log('bad aprove!')
        }
        // please select player, value, region, node, mode, maxplayers
        var token = this.tokenAddress
        // var token = '0x8a2cF6277af2dbE6d870423C3D6d2232ea30D845' // GGT
        console.log('docm', token)

        var node = '0x3373ABddB6459a2341a022DD022ED716cbee8c9f'
        var maxPlayers = 8
        var region = this.gameRegion
        console.log('reg', this.gameRegion)
        var mode = '0'
        this.change_status = 1
        try {
          var c = await this.createMatch({ node, token, maxPlayers, region, mode, shareMethod: '0' })
          console.log('create match result', c)
          var gameId = c.events.MatchCreated.returnValues.gameId
          // now create bet..
          this.change_status = 2
          await this.placeBet({ gameId, alias: this.joinAlias, value })
          this.findInvites(this.findAr, this.findRegion)
          this.overlay = false
        } catch (err) {
          this.overlay = false
        }
      },
      async findInvites (ar, region, arimg) {
        this.findAr = ar
        this.findRegion = region
        // console.log('ar find', ar, region, arimg)
        if (arimg) { // update join image
          for (var i = 0; i < ar.length; i++) {
            if (ar[i] === this.joinAlias.toLowerCase()) {
              this.joinImage = arimg[i]
              break
            }
          }
        }
        // this.invites = (await this.$http.get(this.mainHost + '/invites?region=' + region + '&ar=' + JSON.stringify(ar))).data
        // build invite list..
        var invs = []
        for (var inv of (this.invites)) {
          var b = await this.getBets(inv.gameId)
          if (inv.state !== 2 &&
            b.find(b => ar.includes(b.player.toLowerCase())) &&
            (parseInt(inv.expireTime) > (new Date()).getTime() / 1000)) invs.push(inv)
        }
        this.tinvites = invs
      },
      selectLol (name, region, image) {
        this.joinImage = image
        this.joinAlias = name
        if (this.$refs.invCard) {
          this.$refs.invCard.every(i => {
            i.joinAlias = name
            i.joinImage = image
          })
        }
      },
      ...mapActions('rpc', ['createMatch', 'placeBet', 'aproveBet', 'getBets']),
      ...mapActions('node', ['setNode', 'signNodeMsg']),
      select (id, tourney) {
        this.selectId = id
        this.selected = tourney
      },
      async getGames () {
      },
    },
  }
</script>

<style scoped>
  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }
  .twitch {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
</style>
