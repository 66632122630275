<template>
  <div>
    <span v-if="bets">{{ bets.length }} of {{ info.maxPlayer }} Players</span>
    <span v-else>0 of {{ info.maxPlayer }} Players</span>
  </div>
</template>

<script>
  export default {
    name: 'BetsOfNum',
    props: {
      info: {
        type: Object,
      },
      bets: {
        type: Array,
      },
    },
    data () {
      return {}
    },
  }
</script>
