<template>
  <v-card
    class="mx-auto"
    max-width="450"
  >
    <v-card-title>
      <div class="text-subtitle-1 mb-2">
        State: {{ invite.state }}<br>
        <span v-if="invite.state == 1">
          <span v-if="timeLeft(invite.lockTime) <= 0">TIME LOCKED</span>
          <span v-else>Time Lock: {{ timeLeft(invite.lockTime) }}</span>
          <br>
        </span>
        InviteId: <router-link class="" :to="`/invite/` + invite.gameId">{{ invite.gameId }}</router-link>
      </div>
      <div class="text-caption font-weight-bold grey--text" style="font-size: 11px !important;" @click="getInfo(invite.gameId)">
        Creator: {{ invite.sender }}<br>
        Region: {{ invite.region }}<br>
        Mode: {{ invite.mode }}<br>
        Method: {{ invite.method }}<br>
        Expire time: {{ (new Date(invite.expireTime*1000)).toLocaleTimeString() }}<br>
        Time Left: {{ timeLeft(invite.expireTime) }} secs<br>
      </div>
    </v-card-title>
    <v-divider class="mb-6 mx-4" />
    <v-row
      v-if="timeLeft(invite.lockTime) <= 0"
      class="px-4 grey--text"
      align="center"
    >
      TIME LOCKED
    </v-row>
    <v-row
      v-else-if="alreadyBetting"
      class="px-4 grey--text"
      align="center"
      @click="removeBet(invite.gameId)"
    >
      REMOVE BET
    </v-row>
    <v-row
      v-else
      class="px-4 grey--text"
      align="center"
    >
      <v-col class="py-0 pt-2 d-flex flex-row">
        <v-avatar v-if="showImg" left>
          <v-img width="32" :src="joinImage" />
        </v-avatar>

        <v-text-field
          v-model="joinAlias"
          :disabled="!connected"
          label="Alias"
        />

        <v-text-field
          v-model="joinValue"
          :disabled="!connected"
          class="px-4"
          label="Value"
        />
        <span class="d-flex align-center">
          <v-btn :disabled="!connected" @click="sendJoin(invite.gameId)">Join</v-btn>
        </span>
      </v-col>
    </v-row>

    <v-divider class="my-6 mx-4" />

    <v-row
      class="px-4 grey--text"
      align="center"
    >
      <v-col class="py-0">
        <betof :info="invite" :bets="bets" />
      </v-col>
    </v-row>

    <v-divider class="mt-6 mx-4" />
    <v-card-text v-if="!bets || !Object.keys(bets).length">
      <span class="text-caption font-weight-bold">No bets yet</span>
    </v-card-text>
    <v-card-text v-else>
      <bets-chip ref="betChip" :game-id="invite.gameId" :bets="sbetChips" />
    </v-card-text>
  </v-card>
</template>

<script>
  import {
    mapState,
    mapActions,
  } from 'vuex'
  import Betof from '../../components/invites/Betof.vue'
  import BetsChip from '../../components/invites/BetsChip.vue'
  export default {
    name: 'InviteCard',
    components: { BetsChip, Betof },
    props: {
      invite: {
        type: Object,
      },
      bets: {
        type: Array,
      },
      showImg: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        sbetChips: [],
        alreadyBetting: false,
        joinImage: '',
        joinAlias: 'alias',
        joinValue: 100,
        bettors: null,
        matchState: null,
      }
    },
    computed: {
      ...mapState('platform', ['mainHost', 'championList', 'timeTick']),
      ...mapState('rpc', ['address', 'platformContract', 's_bets']),
      ...mapState('wallet', ['connected', 'contract', 'web3']),
    },
    watch: {
      async address () {
        this.updateBets()
      },
      async s_bets () {
        this.updateBets()
      },
    },
    async mounted () {
      this.matchState = this.invite.matchState
      this.updateBets()
      /* if (!this.matchState) {
        this.matchState = await this.getOracleMatchState(this.invite.gameId)
      }
      if (this.address) {
        try {
          this.isBetting = await this.getBet({ address: this.address, gameId: this.invite.gameId })
        } catch (err) {
          this.isBetting = false
        }
      }
      var bets = await this.getMatchPlayers(this.invite.gameId)
      var bettors = await this.getMatchBettors(this.invite.gameId)
      // console.log('x mounted', bets, bettors)

      var betList = {}
      for (var i = 0; i < bets.players.length; i++) {
        var value = bets.maximumTotalValue[i]
        if (value === '0') continue
        var nChop = 1
        var player = bets.players[i]
        while (player.substr(-nChop, 1) === '0') {
          nChop++
        }
        player = this.web3.utils.toAscii(player.substr(0, player.length - nChop + 1))
        // console.log('p', i, bets.players[i], bets.maximumTotalValue[i])
        betList[player] = value
      }
      this.bets = betList
      // console.log('bets', betList)
      this.bettors = bettors
      // get bet per bettor to get alias info
      */
    },
    methods: {
      async updateBets () {
        this.alreadyBetting = await this.isBetting(this.invite.gameId)
        // get oponent list to display..
        var betChips = []
        for (var b of this.bets) {
          var avatar = 'https://127.0.0.1:8000/gg/avatar'
          betChips.push({ player: b.player, total: b.total, avatar })
        }
        this.sbetChips = betChips
        var region = this.invite.region
        var alias = 'xx' // priorize sender bet or hightst bet
        if (this.bets.length > 0) alias = this.bets[0].player
        var ret = (await this.$http.get(this.mainHost + '/game_status?game=lol&region=' + region + '&alias=' + alias)).data
        console.log('ret', alias, ret)

        if (Object.keys(ret).length > 0) {
          // good ret.. update bet pictures and show opponents..
          betChips = []
          for (b of this.bets) {
            var p = ret.participants.find(p => p.summonerName.toLowerCase() === b.player)
            console.log('got p', p)
            var champName = this.championList[p.championId]
            avatar = `https://ddragon.leagueoflegends.com/cdn/11.15.1/img/champion/${champName}.png` // 'https://127.0.0.1:8000/gg/avatar'
            betChips.push({ player: b.player, total: b.total, avatar })
          }
          this.sbetChips = betChips
        }
      },
      async isBb (gameId) {
        return this.isBetting(gameId)
      },
      ...mapActions('rpc', ['isBetting', 'removeBet', 'getBets', 'getBet', 'placeBet', 'getMatch', 'getMatchBettors', 'getMatchPlayers',
                            'getOracleMatchState']),
      async getInfo (gameId) {
        var info = await this.getMatch(gameId)
        console.log('ginfo', info)
        var modds = await this.platformContract.methods.getMatchOdds(gameId).call()
        console.log('modds', modds)
      },
      timeLeft (time) {
        return parseInt(time - this.timeTick)
      },
      async sendJoin (gameId) {
        this.placeBet({ gameId, alias: this.joinAlias, value: this.joinValue })
      },
    },
  }
</script>
